<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 19:56:09
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.page-info {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #f7f8fa;
  .info-view {
    display: flex;
    flex-direction: column;
    .header-info {
      width: 100%;
      height: 58px;
    }
    .content-info {
      min-width: 1200px;
      max-width: 1200px;
      height: calc(100% - 58px);
      background-color: white;
      padding: 0 120px;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: scroll;
      margin-bottom: 20px;
      .title {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        margin-top: 60px;
      }
      .content {
        color: #666666;
        font-size: 18px;
        margin: 30px 0;
        line-height: 40px;
      }
    }
  }
}
</style>
<template>
  <div class="page-info">
    <div class="info-view">
      <questionnaire-header
        class="header-info"
        :hisPage="pageName"
      ></questionnaire-header>
      <div class="content-info" :style="{maxHeight: (contentHeight)}">
        <div class="title">蚂蚁调研网用户协议</div>
        <p class="content">
          欢迎使用蚂蚁调研网软件及服务！
        </p>
        <p class="content">
          “蚂蚁调研网”（“我们”）为天津咨臣市场调查有限公司及其关联公司独立开发且拥有合法知识产权的服务平台。
        </p>
        <p class="content">
          本协议中的“用户”是指完全同意本协议并完成注册的用户，本协议下也称为“您”。
          <br>特别提示：
          <br>·
          <br>请您先仔细阅读、充分理解本协议内容，尤其是字体加粗部分。如您对本协议内容有任何疑问的，请勿进行下一步操作。您可以通过【个人中心在线提交意见反馈】的方式与我们联系，以便我们为您解释和说明。 您通过页面点击或其他方式确认即表示您已同意本协议。
          <br>·
          <br>如我们对本协议进行修改，我们将通过在“蚂蚁调研网”公告的方式提前予以公布，变更后的协议在公告届满30日起生效。如您不同意变更修改后的协议内容，您有权停止使用相关服务。如您继续登录账户或继续使用服务，则表示您已接受修改的全部条款。
          <br>·
          <br>请确认您拥有充分和完全的能力和权利签署本协议，并履行在本协议项下的权利与义务。
          <br>1. 用户注册及我们提供的服务
          <br>1.1 “蚂蚁调研网”通过互联网向用户提供丰富的网上调研服务，包括各种调查工具、公开调查、趣味测试等（以下简称“服务”）。除非另有明确规定，增加或强化目前服务的任何新功能、新产品，均无条件地适用本协议。
          <br>1.2 用户注册时须提供完整、真实、准确、最新的个人资料，若您提供任何错误、不实、过时或不完整的资料，并为“蚂蚁调研网”所确知，或“蚂蚁调研网”有合理理由怀疑前述资料为错误、不实、过时或不完整的， “蚂蚁调研网”有权在任何时候暂停或终止您的帐号，并拒绝您现在或将来使用全部或部分服务。
          <br>1.3 您注册的账户名称，如存在包括但不限于违反法律法规、损害国家荣誉和利益、损害公众利益、危害国家安全、煽动民族仇恨、民族歧视、破坏民族团结、宣扬邪教和封建迷信、散布谣言、扰乱社会秩序、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人、侵害他人合法权益等情形的， “蚂蚁调研网”有权随时不经通知终止您的账户。
          <br>1.4 “蚂蚁调研网”对用户使用本服务的行为不承担任何责任，即用户需自行对使用本服务的行为及由此导致的任何结果承担风险。包括但不限于以下情形：
          <br>（1）调查问卷的结果可能无法准确反映真实情况；
          <br>（2）您通过本服务购买或获取的第三方产品、服务、资料或其他材料的质量可能不符合您的预期；
          <br>（3）您通过本服务链接到的任何第三方网站的安全性可能存在风险；
          <br>（4）其他第三方可能通过本服务收集、使用您的个人信息；
          <br>（5）您下载或以其他形式获取的任何材料可能存在风险；
          <br>（6）与我们提供的服务无关的其他风险。
          <br>1.5 因互联网行业的特殊性，本服务以“按现状”和“按可得到”的基础提供。 “蚂蚁调研网”不保证服务不会受到网络中断的影响，对服务的及时性、安全性、准确性不作担保。
          <br>1.6 “蚂蚁调研网”有权规定并修改用户使用本服务的方式和措施，包括但不限于决定保留电子邮件讯息或其他上载内容的时间、限制一个帐号可接收电子邮件的数量等。 如“蚂蚁调研网”未能储存或删除本服务的内容或其他讯息，“蚂蚁调研网”不负担任何责任。
          <br>1.7 您签署本协议意味着您与“蚂蚁调研网”建立且仅建立服务关系。本协议无意创设任何除服务关系之外的任何法律关系。
          <br>2.关于用户帐号、密码和安全
          <br>2.1 您一旦注册成功便成为“蚂蚁调研网”的用户，并将得到一个唯一的帐号，该账号是用户身份识别的依据，您有义务自行妥善保管并保证密码和帐号的安全。使用完毕后您应安全退出账号。
          <br>2.2 您使用账户和密码进行的任何操作、发出的指令视为您本人作出。因您自身原因造成的账户、密码等信息被冒用、盗用或非法使用，由此造成的风险和损失由您自行承担。
          <br>2.3 用户的账号仅限本人使用，请勿转让、借用、赠与、继承、租用、借用、售卖或许可任何人使用本人账户，但用户账号内的相关财产权益可被依法继承。
          <br>2.4 用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户可以立即通过【在个人中心在线提交意见反馈】通知我们。
          <br>2.5 您在“蚂蚁调研网”帐号上绑定邮箱或手机，表示您同意通过邮箱或手机接收“蚂蚁调研网”有奖任务和活动的通知。
          <br>2.6 为了防止资源占用，对于用户三个月未登录的帐号，“蚂蚁调研网”有权对该账号进行停用及注销。
          <br>2.7 用户有权随时终止使用本服务并按照“蚂蚁调研网”的流程注销账户。账户注销后用户将无法继续使用本服务，用户与“蚂蚁调研网”之间的权利义务终止， 同时还可能产生以下后果：
          <br>（1）与账户关联的财产收益均将作废；
          <br>（2）如用户在注销前存在违约、侵权等不当行为或未完结合同的，用户仍应承担相应责任；
          <br>（3）一旦注销成功，账户记录、账户功能等将无法恢复或提供。
          <br>3. 个人信息保护
          <br>3.1 我们尊重并致力于保护您的个人信息。“蚂蚁调研网”将按照本协议及《隐私条款》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护相关内容未作明确规定的，均应以《隐私条款》为准。
          <br>3.2 您注册账号或使用本服务时，须填写一些必要的个人信息。若您填写的信息不真实、不完整或不准确的，您可能无法使用本服务或在使用过程中受到限制。
          <br>3.3 您知悉并同意，“蚂蚁调研网”收集个人信息的目的是尽可能多地为您提供参加在线调查的机会，以及为广告商提供一个方便的途径来接触到适合的用户，并且发送具有相关性的内容和广告。
          <br>3.4 “蚂蚁调研网”不会在未经用户合法授权的情况下，公开、编辑或透露您的个人信息及保存在“蚂蚁调研网”中的非公开内容，除非有下列情况：
          <br>（1）有关法律规定或司法机关、行政机关要求；
          <br>（2）在紧急情况下，为维护用户及公众的权益；
          <br>（3）用户在“蚂蚁调研网”兑换奖品时，公开用户的昵称及兑换物品；
          <br>（4）为提供您要求的服务所必须；
          <br>（5）因您违反了本协议或其他规定与“蚂蚁调研网”产生纠纷，“蚂蚁调研网”寻求法律保护的；
          <br>（6）依据《隐私条款》或其他相关协议规定的其他情形。
          <br>3.5 在以下（包括但不限于）几种情况下，您同意“蚂蚁调研网”使用您的个人信息：
          <br>（1）在进行促销或抽奖时，“蚂蚁调研网”可能会与赞助商共享您的个人信息，在这些情况下您可以选择不点击相关促销或抽奖链接来终止信息共享。
          <br>（2）“蚂蚁调研网”可以将您的信息与第三方数据进行匹配。
          <br>（3）“蚂蚁调研网”会进行用户数据统计分析，向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述、宣传“蚂蚁调研网”的服务。
          <br>（4）“蚂蚁调研网”有权在您参与商业调查后，将您的背景信息提供给企业方，以供企业方验证用户是否参与了调查，以及参与调查内容的真实性。
          <br>（5）“蚂蚁调研网”会向用户发送关于“蚂蚁调研网”定期进行的有奖调查，或者“蚂蚁调研网”认为用户会感兴趣的其他调查。如果您不希望收到这样的邮件，只需在提供个人信息时或其他任何时候告知或邮件回复即可。
          <br>3.6 “蚂蚁调研网”不能确信或保证任何个人信息的安全性，用户须自己承担风险。比如用户联机公布可被公众访问的个人信息时，用户有可能会收到未经用户同意的消息；“蚂蚁调研网”的合作伙伴和可通过“蚂蚁调研网”访问的第三方因特网站点和服务或通过抽奖、促销等活动得知用户个人信息的其他第三方会进行独立的数据收集工作等活动，“蚂蚁调研网”对用户及其他任何第三方的上述行为，不负担任何责任。
          <br>4. 关于用户发布问卷
          <br>4.1 若用户在“蚂蚁调研网”注册后，涉及利用本服务在线调查系统发布问卷， 回收到的数据的所有权和使用权归天津咨臣市场调查有限公司所有，天津咨臣市场调查有限公司非独家地授权发布问卷的用户无偿使用回收的数据。同时，天津咨臣市场调查有限公司亦有权授权其他第三方对该数据进行使用。
          <br>4.2 当用户对于数据的应用内容、渠道等发生不正确行为时，天津咨臣市场调查有限公司有权收回用户的使用权。对于不按照天津咨臣市场调查有限公司要求予以执行的，天津咨臣市场调查有限公司有权追究用户的违约责任。用户保证自行承担对于数据的应用所发生的一切法律责任且与天津咨臣市场调查有限公司无关。
          <br>5. 关于 i 积分
          <br>5.1 i 积分获取
          <br>5.1.1您可以通过“蚂蚁调研网”指定途径获得 i 积分，具体在“蚂蚁调研网”详情页、活动条款等公示中说明可予累积 i 积分的情形。
          <br>5.1.2基于 i 积分是用于回馈用户本人的消费行为，故将本服务用于非本人个人消费或任何商业用途的行为，皆不予累积 i 积分，具体在“蚂蚁调研网”详情页、活动条款等公示中说明不予累积 i 积分的情形。
          <br>5.2 i 积分使用
          <br>5.2.1您在满足“蚂蚁调研网”要求的情况下，您可以使用 i 积分用于兑换现金、抽奖等服务，具体以“蚂蚁调研网”相关页面公布的规则为准。
          <br>5.2.2 5您可根据“蚂蚁调研网”现行的积分规则，将所获取的 i 积分兑换相应的积分礼品或服务。积分礼品将不定期进行调整，并以“蚂蚁调研网”实际供应为准，请及时登录平台查询。
          <br>5.2.3您兑换礼品或服务所使用的 i 积分将从您的账户中根据 i 积分累积的先后顺序予以扣除。
          <br>5.2.4如果因各种原因而导致可累积 i 积分的行为未成功，如取消订单等情形，我们将撤销您账户内因此次行为产生的 i 积分。
          <br>5.2.5 i 积分一经兑换礼或服务后，不可退还 。礼品或服务如有质量问题，请拨打客服热线，我们的客服工作人员将为您提供售后服务。
          <br>5.2.6 使用 i 积分+现金形式兑换礼品或服务，在发生符合退款条件的退款情形时，除退回货款外，使用的 i 积分也将返还到您的账户。具体规则请见产品详情页。但交易退款时，使用的 i 积分已超过有效期的，该部分 i 积分不予退还。
          <br>5.3 i 积分清除
          <br>5.3.1您应于 i 积分有效期内使用，请您悉知，您上一年度未使用的 i 积分在当年年度的12月31日过期，例如：您账户中2016年度累积 i 积分于2017年12月31日过期。
          <br>5.3.2 i 积分仅在有效期内有效，如您账号在 i 积分有效期内注销的，则 i 积分同时失效，i 积分到期失效后将从您的账户自动移除。
          <br>5.4 i 积分使用说明
          <br>5.4.1我们可能会不定时调整积分数值或积分规则，并会以网站公告、发送短信、电话、站内信或客户端推送等方式向您发送通知，请您及时予以关注。
          <br>5.4.2对于任何通过不正当手段(包括但不限于作弊、通过机器行为等方式恶意刷单)获取 i 积分权益的行为，我们将在不事先通知的前提下取消该用户已获得的相应 i 积分并取消使用该积分申请使用的订单。
          <br>5.4.3如您使用 i 积分兑换产品或 i 积分加现金方式购买的交易，其中使用 i 积分兑换的部分不支持开具发票。
          <br>5.4.4在存在以下情形时，我们将对您的会员账户暂停或终止提供积分服务，暂时冻结账户内的 i 积分的使用以及限制其他优惠权益的发放:
          <br>a 您违反了本协议的约定;
          <br>b 您的账号存在异常操作，可能产生风险的;
          <br>c 您遭到他人投诉、且对方已经提供了一定证据的
          <br>d 您可能错误操作了他人的会员账户，或者将他人手机号进行注册，或使用他人账户进行了身份验证的;
          <br>e 根据法律法规的规定或行政主管部门的要求;
          <br>f 您利用非正常渠道、手段或其他干扰行为，包括但不限于批量注册账户、恶意刷单等行为，获取我们提供的积分权益、活动补贴等优惠权益;或通过转卖、分享前述优惠权益以非法牟利，或其他影响品牌市场价格、生产经营、品牌名誉的行为。
          <br>除前述e、f款约定的情形外，如您申请恢复服务，解除冻结，请致电会员专用客服热线，根据客服指引，向我们提供相关资料以及您的身份证明等文件，以便我们进行核实。
          <br>6. 合法使用
          <br>6.1 用户在使用本服务时不得从事以下行为：
          <br>（1）上载、张贴、发送任何非法、有害、胁迫、骚扰、侵害、中伤、粗俗、猥亵、诽谤、淫秽、侵害他人隐私、种族歧视等其他另人不快的资料，包括但不限于资讯、文字、软件、音乐、照片、图形、信息或其他资料（以下简称内容）；
          <br>（2）伪造标题或以其他方式操控识别资料，使人误认为该内容为“蚂蚁调研网”所传送；
          <br>（3）将无权传送的内容或违反保密义务取得的内容（例如内部资料、机密资料）进行上载、张贴、发送电子邮件或以其他方式传送；
          <br>（4） 将侵犯他人的名誉权、专利权、商标权、著作权、商业秘密或其他专属权利之内容加以上载、张贴、发送电子邮件或以其他方式传送；
          <br>（5）将广告函件、促销资料、“垃圾邮件”等，加以上载、张贴、发送电子邮件或以其他方式传送；
          <br>（6）将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；
          <br>（7）干扰或破坏本服务或与本服务相连的服务器和网络，或不遵守本服务网络使用之规定；
          <br>（8）对“蚂蚁调研网”系统和程序采取反向工程手段进行破解，对上述系统和程序进行复制、修改、编译、整合或篡改；
          <br>（9）未经相关人同意，收集或不当使用其他人士的个人信息。
          <br>6.2 用户必须遵守中华人民共和国相关法律法规、用户所属、所居住或开展经营活动或其他业务的国家或地区的法律法规及国际法（包括但不限于《中华人民共和国保守国家秘密法》《中华人民共和国计算机信息系统安全保护条例》《计算机软件保护条例》等有关计算机及互联网领域的法律、法规及实施办法），不得将本服务用于非法目的，也不得以非法方式使用我们的服务。
          <br>6.3 用户若利用本服务从事包括但不限于本协议9.1条约定的侵害他人合法权益或违反国家法律法规的行为， “蚂蚁调研网”有权进行调查且不经事先通知终止向该用户提供服务。同时，用户应自行承担相应的法律责任，如因此导致“蚂蚁调研网”或其他任何第三方受损的，用户需要承担赔偿责任（包括但不限于各种损害赔偿费、诉讼费、律师费及其他合理费用）。
          <br>6.4 用户对经由本服务上载、张贴、发送电子邮件或传送的内容的真实性、准确性和完整性负全部责任。用户理解在接受本服务时，有可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，“蚂蚁调研网”均不对任何内容的真实性、适用性及合法性负责，包括但不限于任何错误或纰漏以及衍生的任何损失或损害。 “蚂蚁调研网”有权（但无义务）自行拒绝或删除经由本服务提供的任何内容。用户使用上述内容，应自行承担风险。
          <br>7. 关于用户在“蚂蚁调研网”的公开使用区域张贴内容：
          <br>7.1 “公开使用区域”指一般公众可以使用的区域；
          <br>7.2 您在本服务公开的使用区域张贴的内容，则视为您授权“蚂蚁调研网”免费使用权，“蚂蚁调研网”有权为展示、散布及推广前述张贴的内容之目的， 对上述内容进行复制、修改、出版。本使用权持续到用户将上述内容在本服务中删除。
          <br>7.3 您应对您在公开使用区域张贴的内容的合法性、真实性负责，并应同时遵守本协议的规定。若由此导致任何第三方提出索赔要求或衍生的任何损害或损失，您应承担全部责任。
          <br>8.关于Cookies
          <br>作为提供客户化和个人化服务的一部分，“蚂蚁调研网”可以使用cookies进行存储，以及记录你的信息。cookie是一种小量数据，它从网络服务器传入你的浏览器上，再存储到你电脑的硬盘上。“蚂蚁调研网”将在一些区域提示您，在登录时可设置成允许使用cookie，从而你在一天里不必多次输入登录信息。
          <br>9.服务的修改和终止
          <br>9.1 您理解并同意， “蚂蚁调研网”基于经营策略的调整，可能会对本服务内容进行变更，也可能会中断、中止或终止服务，无论是否事先通知，“蚂蚁调研网”对此不承担责任。
          <br>9.2 如发生以下任何一种情形，“蚂蚁调研网”有权不经通知而中断或终止向您提供服务，或终止您的帐号，或删除、转移您存储、发布在本服务的内容，并且无需就此承担责任：
          <br>（1）根据法律法规规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
          <br>（2）您违反相关法律法规的规定或违反本协议的约定。
          <br>（3）按照法律法规规定，司法机关或主管部门的要求。
          <br>（4）出于安全的原因或其他必要的情形。
          <br>9.3 您有责任自行备份存储在本服务中的数据。如果您的服务被终止，“蚂蚁调研网”有权从服务器上永久地删除您的数据，法律法规另有规定的除外。服务中止或终止后，“蚂蚁调研网”没有义务向您提供或返还数据。
          <br>10.通知及调查
          <br>“蚂蚁调研网”向用户发出的通知及调查，可采用电子邮件、页面公告或常规信件的形式。服务条款的修改或其他事项变更时，“蚂蚁调研网”可以用上述形式进行通知，用户注册时同意此条款后，即默认同意接受“蚂蚁调研网”发送的通知及调查邮件。
          <br>11. 不可抗力、免责
          <br>11.1 “蚂蚁调研网”不对用户发布、转载的问卷提供任何形式的保证；不保证问卷内容能满足您的要求，亦不保存“蚂蚁调研网”的服务不会中断。
          <br>11.2 用户在“蚂蚁调研网”上发表的问卷和其他内容仅表明其个人立场和观点，并不代表“蚂蚁调研网”的立场和观点。作为问卷的发布者，需自行对所发表内容负责，因此造成的一切纠纷由该内容的发布者承担全部法律责任。我方不承担任何责任。
          <br>11.3 “蚂蚁调研网”不对链接的其他网站或资源的内容的合法性和真实性负责，对于因客户使用或信赖从此类网站或资源上获取的内容、宣传、产品 、服务或其他材料而造成的任何直接或间接损失，“蚂蚁调研网”不承担任何责任。
          <br>11.4 因下列原因用户无法正常使用本服务，“蚂蚁调研网”免于承担责任：
          <br>（1）系统停机维护或升级；
          <br>（2）因台风、地震、洪水、瘟疫、雷电、战争或恐怖袭击等不可抗力原因；
          <br>（3）用户的电脑软硬件和通信线路、供电线路出现故障的；
          <br>（4）用户操作不当或通过未经“蚂蚁调研网”授权或认可的方式使用本服务的；
          <br>（5）因病毒、木马、恶意程序供给、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、第三方服务瑕疵或政府行为等原因；
          <br>（6）本协议约定的“蚂蚁调研网”免于承担责任的其他情形。
          <br>尽管有前述约定，“蚂蚁调研网”将采取合理行动积极促使本服务恢复正常。
          <br>11.2 您在“蚂蚁调研网”上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。 您与第三方发生纠纷的，“蚂蚁调研网”不承担责任，但“蚂蚁调研网”有过错的除外。
          <br>12 配套规则及协议
          <br>为使您更好地使用本服务，您需要阅读并遵守《隐私条款》以及“蚂蚁调研网”公示的其他规则。 请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制“蚂蚁调研网”责任的条款。前述协议或规则的部分条款被认定为无效的，不影响其他内容的效力。
          <br>13. 法律的适用和管辖
          <br>本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律，发生的争议应提交上海仲裁委员会，其仲裁裁决是终局的。本协议因与中华人民共和国现行法律相抵触而导致部分无效的，不影响其他部分的效力。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionnaireHeader from "@/components/QuestionnaireHeader.vue";

export default {
  name: "userAgreement",
  components: {
    QuestionnaireHeader,
  },
  data() {
    return {
      pageName: "用户协议",
      hintTitle: "",
      contentHeight: 0,
    };
  },
  created() {
    this.contentHeight = (window.screen.height - 410) + 'px';
  },
  methods: {
    
  },
};
</script>
